'use client';

import SearchIcon from '@mui/icons-material/Search';
import { Button, IconButton } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
export const HeaderSearchPartsButton = () => {
  const handleClick = () => {
    window?.salesPilotPostMessages?.openSearchPage();
  };
  const isMobile = useIsMobile();
  if (isMobile) {
    return <IconButton size="small" color="primary" onClick={handleClick} aria-label="Search parts">
        <SearchIcon sx={{
        fontSize: 24
      }} />
      </IconButton>;
  }
  return <Button onClick={handleClick} variant="contained" color="secondary" sx={{
    fontSize: 16
  }} startIcon={<SearchIcon sx={{
    fontSize: 24
  }} />} data-sentry-element="Button" data-sentry-component="HeaderSearchPartsButton" data-sentry-source-file="HeaderSearchPartsButton.tsx">
      Search parts
    </Button>;
};