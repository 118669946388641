'use client';

import { PropsWithChildren } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import { powermaticTheme } from './powermaticTheme';

/**
 * https://mui.com/material-ui/integrations/nextjs/#theming
 */
export function AppRouterThemeProvider({
  children
}: PropsWithChildren) {
  return <AppRouterCacheProvider data-sentry-element="AppRouterCacheProvider" data-sentry-component="AppRouterThemeProvider" data-sentry-source-file="AppRouterThemeProvider.tsx">
      <ThemeProvider theme={powermaticTheme} data-sentry-element="ThemeProvider" data-sentry-source-file="AppRouterThemeProvider.tsx">
        <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="AppRouterThemeProvider.tsx" />
        {children}
      </ThemeProvider>
    </AppRouterCacheProvider>;
}