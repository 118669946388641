'use client';

import Image from 'next/image';
import { CallOutlined, ChatOutlined, MailOutlineOutlined, PinDropOutlined } from '@mui/icons-material';
import { Box, Container, Grid, Stack, Typography, Link as MuiLink } from '@mui/material';
import PowermaticLogo from './assets/powermatic-logo.svg';
const linkStyles = {
  color: 'primary.contrastText',
  textDecoration: 'underline',
  display: 'flex',
  alignItems: 'center'
};
export const Footer = () => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window?.salesPilotPostMessages?.openChatPage();
  };
  return <Box sx={{
    backgroundImage: {
      // trick to have background colors correct and keep content in Container
      md: 'linear-gradient(to right, #F8F8F8 50%, #18457A 50%)'
    }
  }} data-sentry-element="Box" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <Container sx={{
      px: {
        xs: 0,
        md: 'unset'
      }
    }} data-sentry-element="Container" data-sentry-source-file="Footer.tsx">
        <Grid container component="footer" data-sentry-element="Grid" data-sentry-source-file="Footer.tsx">
          <Grid item xs={12} md={4} pr={{
          xs: 0,
          sm: 3
        }} py={6} sx={{
          bgcolor: '#F8F8F8',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }} data-sentry-element="Grid" data-sentry-source-file="Footer.tsx">
            <MuiLink href="/" sx={{
            position: 'relative',
            height: 88,
            width: {
              xs: '90%',
              lg: '100%'
            }
          }} data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
              <Image src={PowermaticLogo} fill style={{
              objectFit: 'contain'
            }} alt="Powermatic logo" sizes="350px" data-sentry-element="Image" data-sentry-source-file="Footer.tsx" />
            </MuiLink>
          </Grid>
          <Grid item xs={12} md={8} px={{
          xs: 4,
          lg: 8
        }} pt={6} pb={{
          xs: 10,
          md: 6
        }} sx={{
          bgcolor: 'primary.main'
        }} data-sentry-element="Grid" data-sentry-source-file="Footer.tsx">
            <Stack direction={{
            xs: 'column',
            sm: 'row'
          }} spacing={{
            xs: 5,
            lg: 14
          }} justifyContent={{
            xs: 'space-evenly',
            lg: 'flex-start'
          }} data-sentry-element="Stack" data-sentry-source-file="Footer.tsx">
              <Stack data-sentry-element="Stack" data-sentry-source-file="Footer.tsx">
                <Typography variant="h5" component="h3" color="primary.contrastText" mb={4} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                  Quick Links
                </Typography>
                <MuiLink href="/about-us" data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
                  <Typography variant="bodySmall" color="#D4D7DD" mb={2} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                    About us
                  </Typography>
                </MuiLink>
                <MuiLink href="/value-added" data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
                  <Typography variant="bodySmall" color="#D4D7DD" mb={2} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                    Services
                  </Typography>
                </MuiLink>
                <MuiLink href="/compliance" data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
                  <Typography variant="bodySmall" color="#D4D7DD" mb={2} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                    Compliance
                  </Typography>
                </MuiLink>
                <MuiLink href="/contact" data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
                  <Typography variant="bodySmall" color="#D4D7DD" mb={2} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                    Contact
                  </Typography>
                </MuiLink>
                <MuiLink href="/careers" data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
                  <Typography variant="bodySmall" color="#D4D7DD" mb={2} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                    Careers
                  </Typography>
                </MuiLink>
              </Stack>
              <Stack data-sentry-element="Stack" data-sentry-source-file="Footer.tsx">
                <Typography variant="h5" component="h3" color="primary.contrastText" mb={4} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                  Contact
                </Typography>
                <MuiLink href="#" onClick={handleClick} sx={linkStyles} mb={1} data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
                  <ChatOutlined sx={{
                  mr: 2
                }} data-sentry-element="ChatOutlined" data-sentry-source-file="Footer.tsx" />
                  Start the chat
                </MuiLink>
                <MuiLink href="mailto:insideSales@powermatic.net" sx={linkStyles} mb={1} data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
                  <MailOutlineOutlined sx={{
                  mr: 2
                }} data-sentry-element="MailOutlineOutlined" data-sentry-source-file="Footer.tsx" />
                  insideSales@powermatic.net
                </MuiLink>
                <MuiLink href="tel:800-966-8513" sx={linkStyles} mb={1} data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
                  <CallOutlined sx={{
                  mr: 2
                }} data-sentry-element="CallOutlined" data-sentry-source-file="Footer.tsx" />
                  Call: 800-966-8513
                </MuiLink>
                <MuiLink href="https://maps.app.goo.gl/U1j5uoSPBH5UvSX49" target="_blank" rel="noopener noreferrer" sx={linkStyles} mb={1} data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
                  <PinDropOutlined sx={{
                  mr: 2
                }} data-sentry-element="PinDropOutlined" data-sentry-source-file="Footer.tsx" />
                  1264 Stealth Street Livermore, CA 94551, USA
                </MuiLink>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>;
};