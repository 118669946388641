import { forwardRef } from 'react';
import * as React from 'react';
import { LinkProps as NextLinkProps } from 'next/dist/client/link';
import { Inter } from 'next/font/google';
import NextLink from 'next/link';
import { colors, alpha } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import createTheme from '@mui/material/styles/createTheme';
const LinkBehaviour = forwardRef<HTMLAnchorElement, NextLinkProps>(function LinkBehaviour(props, ref) {
  return <NextLink ref={ref} {...props} />;
});
const inter = Inter({
  subsets: ['latin']
});
const fontFamily = [inter.style.fontFamily, 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(',');
let theme = createTheme({
  typography: {
    fontFamily
  },
  palette: {
    primary: {
      main: '#18457A',
      light: '#1B5394'
    },
    secondary: {
      main: '#00EAC0'
    },
    error: {
      main: '#D32F2F',
      dark: '#C62828',
      light: '#EF5350'
    },
    success: {
      main: '#1C8259',
      dark: '#156645',
      light: '#6CE0B1'
    },
    warning: {
      main: '#FF9800',
      dark: '#ED6C02',
      light: '#FF9800'
    },
    text: {
      primary: '#2E2C34',
      secondary: '#696974',
      disabled: '#72727E'
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF'
    },
    accent: {
      main: '#0091DC',
      light: '#1E9EE8',
      contrastText: '#FFFFFF'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2000
    }
  }
});
theme = createTheme(theme, {
  palette: {
    primary: {
      '50p': alpha(theme.palette.primary.main, 0.5),
      '12p': alpha(theme.palette.primary.main, 0.12),
      '8p': alpha(theme.palette.primary.main, 0.08),
      '4p': alpha(theme.palette.primary.main, 0.04)
    },
    secondary: {
      '50p': alpha(theme.palette.secondary.main, 0.5),
      '12p': alpha(theme.palette.secondary.main, 0.12),
      '8p': alpha(theme.palette.secondary.main, 0.08)
    },
    error: {
      '50p': alpha(theme.palette.error.main, 0.5),
      '12p': alpha(theme.palette.error.main, 0.12),
      '8p': alpha(theme.palette.error.main, 0.08)
    },
    success: {
      '50p': alpha(theme.palette.success.main, 0.5),
      '12p': alpha(theme.palette.success.main, 0.12),
      '8p': alpha(theme.palette.success.main, 0.08)
    },
    warning: {
      '50p': alpha(theme.palette.warning.main, 0.5),
      '12p': alpha(theme.palette.warning.main, 0.12),
      '8p': alpha(theme.palette.warning.main, 0.08),
      contrastText: colors.common.white
    }
  },
  typography: {
    h1: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 48,
      lineHeight: '150%',
      letterSpacing: 0,
      color: theme.palette.text.primary
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 48,
      lineHeight: '150%',
      letterSpacing: 0,
      color: theme.palette.text.primary
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 36,
      lineHeight: '130%',
      letterSpacing: 0,
      color: theme.palette.text.primary
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '130%',
      letterSpacing: '0.15px',
      color: theme.palette.text.primary
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '130%',
      letterSpacing: '0.15px',
      color: theme.palette.text.primary
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '130%',
      letterSpacing: '0.15px',
      color: theme.palette.text.primary
    },
    body1: undefined,
    body2: undefined,
    bodyLarge: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '120%',
      letterSpacing: '0.15px',
      color: theme.palette.text.secondary
    },
    bodyMedium: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '120%',
      letterSpacing: '0.15px',
      color: theme.palette.text.secondary
    },
    paragraph: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '150%',
      letterSpacing: '0.15px',
      color: theme.palette.text.secondary
    },
    bodySmall: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '120%',
      letterSpacing: '0.15px',
      color: theme.palette.text.secondary
    },
    bodyLargePrimary: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '120%',
      letterSpacing: '0.15px',
      color: theme.palette.primary.main
    },
    bodyMediumPrimary: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '120%',
      letterSpacing: '0.15px',
      color: theme.palette.primary.main
    },
    bodySmallPrimary: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '120%',
      letterSpacing: '0.15px',
      color: theme.palette.primary.main
    },
    bodySmallError: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '120%',
      letterSpacing: '0.15px',
      color: theme.palette.error.main
    },
    button: {
      textTransform: 'none',
      fontSize: '14px'
    }
  }
});
export const powermaticTheme = createTheme(theme, {
  typography: {
    body1: theme.typography.bodyLarge
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variant: 'bodyLarge',
        variantMapping: {
          bodyLarge: 'p',
          bodyMedium: 'p',
          paragraph: 'p',
          bodySmall: 'p',
          bodyLargePrimary: 'p',
          bodyMediumPrimary: 'p',
          bodySmallPrimary: 'p',
          bodySmallError: 'p'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          borderRadius: 0,
          height: 30
        },
        root: {
          borderRadius: 0,
          height: 40,
          '&:hover, &:focus': {
            textDecoration: 'none',
            outline: 0
          }
        },
        sizeLarge: {
          borderRadius: 0,
          height: 50
        },
        textSizeSmall: {
          textDecoration: 'underline',
          '&:hover, &:focus': {
            textDecoration: 'underline'
          }
        },
        textWarning: {
          color: theme.palette.warning.dark
        },
        containedWarning: {
          backgroundColor: theme.palette.warning.dark
        },
        outlinedWarning: {
          color: theme.palette.warning.dark,
          borderColor: theme.palette.warning.dark
        }
      },
      defaultProps: {
        disableElevation: true
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehaviour
      }
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehaviour,
        underline: 'hover'
      }
    },
    MuiPaper: {
      defaultProps: {
        square: true,
        variant: 'elevation'
      },
      styleOverrides: {
        outlined: {
          border: 'none',
          boxShadow: theme.shadows[2],
          borderTop: '5px solid',
          borderTopColor: theme.palette.secondary.main
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 50,
          padding: theme.spacing(1, 1.5)
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 50
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 2)
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {
            backgroundColor: theme.palette.grey[50]
          }
        }
      }
    },
    MuiTableCell: {
      defaultProps: {},
      styleOverrides: {
        head: () => ({
          backgroundColor: '#FAFAFB',
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: 1.2,
          letterSpacing: 1,
          textTransform: 'uppercase',
          color: theme.palette.text.primary,
          borderBottom: '0 none',
          '&:first-of-type': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px'
          },
          '&:last-of-type': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px'
          }
        }),
        body: () => ({
          borderColor: theme.palette.divider,
          color: theme.palette.text.secondary,
          [theme.breakpoints.down('md')]: {
            fontSize: '14px'
          }
        }),
        sizeSmall: {
          padding: theme.spacing(1, 2),
          '&.MuiTableCell-body': {
            fontSize: 14
          }
        },
        sizeMedium: {
          padding: theme.spacing(2, 1.5),
          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1.5, 0.5)
          }
        }
      }
    },
    MuiAlert: {
      defaultProps: {
        severity: 'error'
      },
      styleOverrides: {
        root: {
          width: '100%',
          marginBottom: theme.spacing(2)
        }
      }
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          width: 'fit-content'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: 'fit-content'
        },
        label: {
          fontWeight: 500
        }
      },
      defaultProps: {
        slotProps: {
          typography: {
            variant: 'bodyMedium'
          }
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          height: theme.spacing(5),
          backgroundColor: theme.palette.primary['4p'],
          color: theme.palette.text.secondary,
          '&.MuiToggleButton-root.Mui-selected': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark
            }
          }
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'lg'
      }
    }
  }
});